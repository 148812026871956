
/* .parent {
  display: flex;
  flex-direction:row;
} */
.Stopwatch {
  margin-left: 30px;
  margin-right: 30px;
  border-radius: 4px;
  padding: 20px;
  width: 400px;
  background-color: white;
  box-shadow: 0 0 1PX;
  border: solid 0px transparent;
  border-color: lightgray;
}


.Stopwatch-header {
  font-size: 35px;
  font-weight: bold;
}

button {
  background-color: #15b1cc;
  border: solid 1px transparent;
  border-radius: 4px;
  padding: 10px 20px;
  color: #ffffff;
  font-size: 16px;
  margin: 0 4px;
  cursor: pointer;
}
button:hover {
  background-color: #106ba3;
}
.Stopwatch button {
  padding: 12px 32px;
  font-size: 20px;
}
.Stopwatch-display {
  padding: 40px 0px;
  font-size: 40px;
}
.Stopwatch-text {
}

@media (max-width: 1000px) {
  .Stopwatch {
    margin-bottom: 10px;
    width: 385px;
  }
  .Stopwatch-header {
    font-size: 17px;
    font-weight: bold;
  }  
  .Stopwatch-display {
    padding: 10px 0px;
    font-size: 20px;
  }
  .Stopwatch button {
    padding: 6px 16px;
    font-size: 12px;
  }
}