.Notes form {
    padding-bottom: 15px;
  }
  
  .Notes form textarea {
    height: 300px;
    font-size: 24px;
  }

  @media (max-width: 1000px) {
    .Notes form textarea {
      height: 100px;
      font-size: 18px;
    }
  }