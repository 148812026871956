.NewNote form {
  padding-bottom: 15px;
}

.NewNote form textarea {
  height: 300px;
  font-size: 24px;
  margin-top: 5px;
}

/* .parent {
  display: flex;
  flex-direction:row;
} */

/* .column {
  flex: 1 1 0px;
  border: 0px solid black;
}
 */

.Timers {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
 
}
@media (max-width: 1000px) {
  .Timers {
    flex-direction: column;
    align-items: center;
  }
}

.Metronome {
  margin-left: 30px;
  margin-right: 30px;
  border-radius: 4px;
  padding: 20px;
  width: 600px;
  background-color: white;
  box-shadow: 0 0 1PX;
  padding-top: 10px;
  border: solid 0px transparent;
  border-color: lightgray;
}

.Metronome-header {
  font-size: 35px;
  font-weight: bold;
}
.Metronome-details {
  font-size: 25px;
  font-weight: bold;
  color: cadetblue;
}
button {
  background-color: #15b1cc;
  border: solid 1px transparent;
  border-radius: 4px;
  padding: 10px 20px;
  color: #ffffff;
  font-size: 16px;
  margin: 0 4px;
  cursor: pointer;
}
button:hover {
  background-color: #106ba3;
}
.Metronome-button {
  /* width: 250;
  border: solid 1px transparent;
  border-radius: 4px;
  padding: 12px 32px;
  font-size: 20px;
  font-weight: normal; */
  padding: 12px 32px;
  font-size: 18px;
  font-weight: normal;
  margin: 0px 0px;
}
input[type=number] {
  width: 80px;
}

@media (max-width: 1000px) {
  .NewNote form textarea {
    height: 100px;
    font-size: 18px;
  }
  .Metronome {
    width: 385px;
    
  }
  .Metronome-header {
    font-size: 17px;
    font-weight: bold;
  }
  .Metronome-details {
    font-size: 20px;
  }
  .Metronome-button {
    padding: 6px 16px;
    font-size: 12px;
    font-weight: normal;
    margin: 0px 0px;
  }
}
